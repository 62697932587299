body {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}
input {
  background-color: #f1f5f9;
  padding: 0.1em 0.25em;

  border: none;
  font-family: "Source Serif Pro";
  font-size: 1.2em;
}
.chess-board-wrapper {
  max-width: 100vw;
  max-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.chess-board {
  display: flex;
  height: 94%;
  width: 94%;
  overflow-x: scroll;
  aspect-ratio: 1/1;
  flex-direction: column;
  margin: 0 auto;
}
.row {
  display: flex;
  flex: 1;
}
.square {
  flex: 1 1 10em;
  justify-content: center;
  align-items: center;
  display: flex;
  min-width: 5em !important;
  min-height: 3em !important;
}
.square img {
  width: 4em;
  color: white;
  z-index: 0;
}
.light {
  background-color: #a3a3a3;
}
.dark {
  background-color: #7f1d1d;
}
.white {
  color: white !important;
  fill: #ffffff !important;
}
.green {
  background-color: green !important;
}
.square:hover {
  background-color: red;
}
.inplay {
  background-color: yellow !important;
}
.purple {
  background-color: purple !important;
}
.inhand {
  width: 1em !important;
  background-color: indianred;
}
.welcomescreen-wrapper {
  width: 50em;
  height: fit-content;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: black;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  padding: 1.2em;
  max-width: 92%;
  border-radius: 1em;
  gap: 0.2em;
  font-family: "Source Serif Pro", serif;
  font-weight: 800;
}

.welcomescreen-background {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(000, 000, 000, 0.6);
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

h2 {
  font-weight: 200;
  font-size: 1.3em;
}
.App {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.sideboard-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d4d4d4;
  color: #111827;
  flex: 1;
  min-width: 20em;
  max-height: 100vh;
  overflow-y: scroll;
  font-family: "Source Serif Pro", serif !important;
}
.sideboard-wrapper h1 {
  margin-top: 0.5em;
}
.moves-list {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding-bottom: 3em;
}
.move-wrapper {
  color: black;
  background-color: #e2e8f0;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.5em;
}
.move-wrapper:nth-child(odd) {
  background-color: #94a3b8;
  color: white;
}
.h-stack {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1em;
  max-width: 30em;
}
.v-stack {
  gap: 1em;
}
.game-wrapper {
  display: flex;
  background-color: #633227;
  flex-wrap: wrap;
  width: 100%;
}
.move-wrapper h1 {
  font-size: 1em;
  font-weight: 100;
  margin: 0;
}
.move-wrapper h5 {
  margin-left: auto;
  margin-right: 1em;
}
.cool-purple-button {
  padding: 0.25em 1em;
  font-size: 1em;
  flex: 1;
  font-weight: 900;
  background-color: #6d28d9;
  margin-left: auto;
  border-radius: 0.33em;
  color: white;
  border: none;
  box-shadow: -3px 3px 30px 10px rgba(109, 40, 217, 0.5);
  font-family: "Source Serif Pro", serif !important;
  transition: 0.25s;
  cursor: pointer;
}
.cool-purple-button:hover {
  transform: scale(0.95);
}
.form-wrapper {
  display: flex;
  gap: 0.33em;
  flex-direction: column;
  justify-content: flex-end;
}
.username-input {
  width: 12em;
  margin: 0 auto;
  flex: none;
}
input {
  flex: 1;
  min-height: 2em;
}
@media screen and (max-width: 600px) {
  .welcomescreen-background {
    background-color: black;
    display: flex;
    margin-bottom: auto;
  }
  .welcomescreen-wrapper {
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    margin-top: 15em;
  }
  .welcomescreen-wrapper h1 {
    margin-bottom: 0.25em;
  }
}

.welcomescreen-wrapper a {
  color: white;
  font-weight: 100;
  font-size: 1.25em;
}
.welcomescreen-background a:hover {
  font-style: italic !important;
  color: red;
}
.alert-wrapper {
  position: fixed;
  top: 0;
  margin-top: 1.5em;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1em;
  background-color: white;
  box-shadow: 0em 0em 2em 0 rgba(000, 000, 000, 0.3);
  max-width: max-content;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.33em;
  padding-bottom: 1.2em !important;
  width: 30em;
  max-width: 90% !important;
  color: black;
  align-items: center;
}
.alert-wrapper h1 {
  padding: 0.25em 1em;
  padding-bottom: 0;
  font-size: 1;
  color: var(--textcolor) !important;
}
.alert-wrapper p {
  text-align: center;
  font-size: 1.25em;
  max-width: 90%;
  margin: 0 auto;
  color: var(--textcolor) !important;
}
.alert-wrapper .options-wrapper {
  display: flex;
  justify-content: center;
  gap: 1em;
  width: 90%;
}
.alert-wrapper .options-wrapper button {
  padding: 0.25em 1em;
  max-width: 12em;
  font-family: "Source Serif Pro", serif !important;
  font-size: 1.3em;
  border: none;
  width: 100% !important;
  border-radius: 0.25em;
  margin: 0 auto;
  margin-top: 0.5em;
}
.user-info h5,
.enemy-info h5 {
  display: flex;
  font-size: 1.2em;
  color: black;
  gap: 0.5em;
  position: absolute;
  padding: 0.25em 1.5em;
  border-radius: 0em 0em 1em 0em;
  background-color: #d4d4d4;
  z-index: 9999;
}
.enemy-info h5 {
  bottom: 0;
  right: 0 !important;
  border-radius: 1em 0em 0em 0em;
}
.flip {
  transform: rotate(180deg);
}
.flip img,
.flip h5 {
  transform: rotate(180deg);
}
.flip .enemy-info h5 {
  border-radius: 0em 0em 1000em 0em;
}

.flip .user-info h5 {
  border-radius: 1000em 0em 0em 0em !important;
}
